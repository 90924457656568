var contactModule = angular.module('com.aa.contact', ['ngRoute', 'httpModule', 'angularFileUpload']);

contactModule.config(['$locationProvider', function($locationProvider) {
    $locationProvider.hashPrefix('');
}]);

contactModule.contactAA = AAcom('browserdetect', 'utilities', 'commonsetup', 'ajax', 'aaDatePicker', 'aaAutoComplete', 'airportLookup', function (AAUI) {

    contactModule.dateFormat = AAUI.getProperty('dateFormat');
    contactModule.bagsLocale = AAUI.getProperty('bagsLocale');
    contactModule.busyMessage = AAUI.getProperty('loadingTxt');
    contactModule.busyContainer = angular.element('body');
    contactModule.showResource = AAUI.getProperty('showResource') || '';
    contactModule.ERRCODE1204 = AAUI.getProperty('ERRCODE1204');
    contactModule.ERRCODE1205 = AAUI.getProperty('ERRCODE1205');
    contactModule.ERRCODE408 = AAUI.getProperty('ERRCODE408');
    contactModule.ERRCODE409 = AAUI.getProperty('ERRCODE409');
    contactModule.ERRCODE1221 = AAUI.getProperty('ERRCODE1221');
    contactModule.ERRCODE183 = AAUI.getProperty('ERRCODE183');
    contactModule.ERRCODE845 = AAUI.getProperty('ERRCODE845');
    contactModule.ERRCODE1235 = AAUI.getProperty('ERRCODE1235');
    contactModule.ERRCODE74 = AAUI.getProperty('ERRCODE74');
    contactModule.ERRCODE1206 = AAUI.getProperty('ERRCODE1206');
    contactModule.ERRCODE1228 = AAUI.getProperty('ERRCODE1228');
    contactModule.ERRCODE190 = AAUI.getProperty('ERRCODE190');
    contactModule.ERRCODE1251 = AAUI.getProperty('ERRCODE1251');
    contactModule.ERRCODE1211 = AAUI.getProperty('ERRCODE1211');
    contactModule.ERRCODE1212 = AAUI.getProperty('ERRCODE1212');
    contactModule.ERRCODE1253 = AAUI.getProperty('ERRCODE1253');
    contactModule.ERRCODE1239 = AAUI.getProperty('ERRCODE1239');
    contactModule.ERRCODE1240 = AAUI.getProperty('ERRCODE1240');
    contactModule.ERRCODE1224 = AAUI.getProperty('ERRCODE1224');
    contactModule.ERRCODE1252 = AAUI.getProperty('ERRCODE1252');
    contactModule.ERRCODE800 = AAUI.getProperty('ERRCODE800');
    contactModule.ERRCODE858 = AAUI.getProperty('ERRCODE858');
    contactModule.ERRCODE1223 = AAUI.getProperty('ERRCODE1223');
    contactModule.ERRCODE1226 = AAUI.getProperty('ERRCODE1226');
    contactModule.ERRCODE1213 = AAUI.getProperty('ERRCODE1213');
    contactModule.ERRCODE1214 = AAUI.getProperty('ERRCODE1214');
    contactModule.ERRCODE1218 = AAUI.getProperty('ERRCODE1218');
    contactModule.ERRCODE1219 = AAUI.getProperty('ERRCODE1219');
    contactModule.ERRCODE1256 = AAUI.getProperty('ERRCODE1256');
    contactModule.ERRCODE1257 = AAUI.getProperty('ERRCODE1257');
    contactModule.ERRCODE1266 = AAUI.getProperty('ERRCODE1266');
    contactModule.ERRCODE1225 = AAUI.getProperty('ERRCODE1225');
    contactModule.ERRCODE1255 = AAUI.getProperty('ERRCODE1255');
    contactModule.ERRCODE843 = AAUI.getProperty('ERRCODE843');
    contactModule.ERRCODE1227 = AAUI.getProperty('ERRCODE1227');
    contactModule.ERRCODE1229 = AAUI.getProperty('ERRCODE1229');
    contactModule.ERRCODE1260 = AAUI.getProperty('ERRCODE1260');
    contactModule.ERRCODE1220 = AAUI.getProperty('ERRCODE1220');
    contactModule.ERRCODE1335 = AAUI.getProperty('ERRCODE1335');
    contactModule.ERRCODE510 = AAUI.getProperty('ERRCODE510');
    contactModule.ERRCODE1456 = AAUI.getProperty('ERRCODE1456');
    contactModule.ERRCODE1457 = AAUI.getProperty('ERRCODE1457');
    contactModule.ERRCODE1458 = AAUI.getProperty('ERRCODE1458');
    contactModule.ERRCODE1459 = AAUI.getProperty('ERRCODE1459');
    contactModule.ERRCODE1467 = AAUI.getProperty('ERRCODE1467');
    contactModule.ERRCODE1468 = AAUI.getProperty('ERRCODE1468');
    contactModule.ERRCODE1469 = AAUI.getProperty('ERRCODE1469');
    contactModule.ERRCODE1470 = AAUI.getProperty('ERRCODE1470');
    contactModule.ERRCODE1328 = AAUI.getProperty('ERRCODE1328');
    contactModule.ERRCODE1334 = AAUI.getProperty('ERRCODE1334');
    contactModule.ERRCODE417 = AAUI.getProperty('ERRCODE417');
    contactModule.ERRCODE1516 = AAUI.getProperty('ERRCODE1516');
    contactModule.ERRCODE1517 = AAUI.getProperty('ERRCODE1517');
    contactModule.ERRCODE1518 = AAUI.getProperty('ERRCODE1518');
    contactModule.ERRCODE1259 = AAUI.getProperty('ERRCODE1259');
    contactModule.ERRCODE385 = AAUI.getProperty('ERRCODE385');
    contactModule.ERRCODE1066 = AAUI.getProperty('ERRCODE1066');
    contactModule.ERRCODE206 = AAUI.getProperty('ERRCODE206');
    contactModule.ERRCODE1571 = AAUI.getProperty('ERRCODE1571');
    contactModule.ERRCODE1572 = AAUI.getProperty('ERRCODE1572');
    contactModule.ERRCODE1612 = AAUI.getProperty('ERRCODE1612');

    contactModule.errorMessage408 = AAUI.getProperty('errorMessage408');
    contactModule.errorMessage409 = AAUI.getProperty('errorMessage409');
    contactModule.topics = AAUI.getProperty('json.topics');
    contactModule.subjectsMap = AAUI.getProperty('json.topicsAndSubjects');
    contactModule.reasonsMap = AAUI.getProperty('json.topicsAndReasons');
    contactModule.keepTopicTxt = AAUI.getProperty('keepTopictext');
    contactModule.changeTopicTxt = AAUI.getProperty('changeTopicText');
    contactModule.goBackModal = AAUI.getProperty('goBackModal');
    contactModule.submitModal = AAUI.getProperty('submitModal');
    contactModule.ok = AAUI.getProperty('ok');
    contactModule.userError = AAUI.getProperty('userError');
    contactModule.serverError = AAUI.getProperty('serverError');
    contactModule.totalSizeExceedError = AAUI.getProperty('totalSizeExceedError');
    contactModule.totalLimitExceedError = AAUI.getProperty('totalLimitExceedError');
    contactModule.topicSelector = 'select[name$="topic"]';
    contactModule.subjectSelector = 'select[name$="subject"]';
    contactModule.reasonSelector = 'select[name$="reason"]';

    AAUI.util = new aa_Utilities();

    var initAddressForm = function () {
        AAUI.onKeyChange('select[name="contactInfo.addressInfo.country"]', function () {
            var value = $j(this).val();
            if (value === '') {
                value = 'US';
            }
            $j(".js-state").hide();
            if (value == 'US' || value == 'CA') {
                $j(".js-state[data-country=" + value + "]").css('display', 'inline-block');
            }
            $j(".js-postalCode .icon-required").hide();
            $j(".js-postalCode .hidden-accessible").hide();
            if (value == "US" || value == "CA" || value == "GB" || value == "MX") {
                $j(".js-postalCode .icon-required").show();
                $j(".js-postalCode .hidden-accessible").show();
            }
        });
    };

    var initFlightInfo = function () {
        AAUI.onChange('input[name="hasFlightInfo"]', function () {
            var value = $j(this).val();
            if (value === 'true') {
                $j(".flightInfo").show();
            } else {
                $j(".flightInfo").hide();
            }
        });
    };

    var initGuardianInfo = function () {
        AAUI.onChange('input[name="hasGuardianInfo"]', function () {
            var value = $j(this).val();
            if (value === 'true') {
                $j("#guardianInfo").removeClass("hide-guardian");
            } else {
                $j("#guardianInfo").addClass("hide-guardian");
            }
        });
    };


    var assistanceTypeComments = function () {
        $j('input[value="false"]').attr('checked', 'checked');
        AAUI.onChange('input[id="assistanceTypeInfo.otherAssistance"]', function () {
            if ($j(this).attr('checked')) {
                $j("#assistanceTypeInfoComments").show();
            } else {
                $j("#assistanceTypeInfoComments").hide();
            }
        });
    };

    var onModifiedPrimaryEmailShowConfirmEmail = function () {
        AAUI.onInput('input[name="contactInfo.emailPhoneForm.email"]', function () {
            $j('input[name="contactInfo.emailPhoneForm.confirmEmail"]').val('').trigger('change');
            $j('#confirmEmail').show();
        });
    };

    var emptyPrimaryEmailShowConfirmEmail = function () {
        var primaryEmailValue = $j('input[name="contactInfo.emailPhoneForm.email"]').val();
        if (primaryEmailValue == '') {
            $j("#confirmEmail").show();
        }
    };

    AAUI.onClick("#printButton", function () {
        window.print();
    });

    AAUI.startSpinner = function () {
        $j(contactModule.busyContainer).aaBusy({message: contactModule.busyMessage}).start();
    };

    AAUI.stopSpinner = function () {
        $j(contactModule.busyContainer).aaBusy().stop();
    };

    AAUI.initPage = function () {
        AAUI.characterCount.init();
        initAddressForm();
        initFlightInfo();
        initGuardianInfo();
        assistanceTypeComments();
        onModifiedPrimaryEmailShowConfirmEmail();
        emptyPrimaryEmailShowConfirmEmail();
    };

    $j(document).ready(function () {
        AAUI.initPage();
    });
});
